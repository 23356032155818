const Span = () => <span></span>;

const SecWhoWeContant = () => {
  return (
    <div className="col-12 col-lg-6">
      <div className="who-we-contant">
        <div className="dream-dots" data-aos="fade-up" dir="rtl">
          {Array(7)
            .fill()
            .map((key) => (
              <Span />
            ))}
        </div>
        <h4 data-aos="fade-up" style={{ fontWeight: 700, textAlign: "right" }}>
          درباره ما
        </h4>
        <h6
          data-aos="fade-up"
          style={{ fontWeight: 700, textAlign: "right", color: "white" }}
        >
          پراپ تریدینگ سرمایه گذار برتر
        </h6>
        <p data-aos="fade-up" style={{ textAlign: "right" }}>
          شاید کمتر کسی در فضای کسب و کار فارکس و حتی سایر بازارهای مالی وجود
          داشته که نام سرمایه گذار برتر برایش نا آشنا باشد. این یک ادعا نیست و
          ما با حمایت شما معامله گران موفق به این اعتماد دست یافته ایم.
        </p>
        <p data-aos="fade-up" style={{ textAlign: "right" }}>
          سال 2021 انواع مشکلات، تحریم ها و مسدود شدن پرداختی ها تنها بخش کوچکی
          از چالش های موجود در مسیر پیشرفت معامله گران ایرانی بود. در آگوست همان
          سال با وجود ریسک ها و خطراتی که وجود داشت فعالیت شرکت در کشور امارات
          متحده عربی، به عنوان اولین پراپ تریدینگ تمام ایرانی آغاز گردید. تا
          مسیر رشد خانواده ی بزرگ فارکس ایران که از برترین تریدرهای دنیا به شمار
          می روند هموارتر گردد.
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <a className="btn dream-btn mt-30 farsi-btn" href="#">
            بیشتر بخوانید
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecWhoWeContant;

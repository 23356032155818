import { useEffect } from "react";
import "./style/HomeDemo2.scss";

import {
  VerticalSocial,
  SingleCoolFact,
  service_single_content,
  timelineInfo,
} from "../../data/data-containers/data-HomeDemo2.js";

import { HomeDemo2About1, HomeDemo2BgRoadmap } from "../../utils/allImgs";

import Header from "../../layouts/Header";
import Footer from "../../layouts/FooterPages";

import SecWelcomeArea from "./SecWelcomeArea";
import SecVerticalSocial from "./SecVerticalSocial";
import SecTrust from "./SecTrust";
import SecAboutUsClient from "./SecAboutUsClient";
import SecOurServices from "./SecOurServices";
import SecOurRoadmap from "./SecOurRoadmap";
import sgbAbout from "../../assets/img/blog-img/sgb-about.png";
let HomeDemo2 = () => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.backgroundImage =
      "linear-gradient(to right, #1d0498, #341f97)";
  }, []);
  return (
    <>
      <Header />
      <div className="HomeDemo2">
        <SecWelcomeArea />
        <SecVerticalSocial data={VerticalSocial} />
        <SecTrust data={SingleCoolFact} />
        <SecAboutUsClient img={sgbAbout} />
        <div className="clearfix" />
        {/* <SecOurServices data={service_single_content} /> */}
        {/* <SecOurRoadmap data={timelineInfo} img={HomeDemo2BgRoadmap} /> */}
        {/* <SecOurFeatures
          data={ServiceBlock}
          imgPhone={HomeDemo2ImgPhone}
          Rings={HomeDemo2RingsBg}
        />
        <SecDistribution img={HomeDemo2Allocation} data={TokenText} /> */}
      </div>
      <Footer />
    </>
  );
};

export default HomeDemo2;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-head-subscribe{
	visibility: visible;
	animation-delay: 0.3s;
	animation-name: fadeInUp;
}

.text-head-subscribe{
	visibility: visible;
	animation-delay: 0.4s;
	animation-name: fadeInUp;	
}`, "",{"version":3,"sources":["webpack://./src/template/HomeDemo3/SecSubscribe/SectionHeading/SectionHeading.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,qBAAqB;CACrB,wBAAwB;AACzB;;AAEA;CACC,mBAAmB;CACnB,qBAAqB;CACrB,wBAAwB;AACzB","sourcesContent":[".title-head-subscribe{\n\tvisibility: visible;\n\tanimation-delay: 0.3s;\n\tanimation-name: fadeInUp;\n}\n\n.text-head-subscribe{\n\tvisibility: visible;\n\tanimation-delay: 0.4s;\n\tanimation-name: fadeInUp;\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

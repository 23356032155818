import { HomeDemo2Dollar } from "../../../utils/allImgs";

import SecWelcomeContent from "./SecWelcomeContent";
import code from "../../../assets/img/bg-img/hero.png";
import apple from "../../../assets/img/bg-img/apple.png";
import swap from "../../../assets/img/bg-img/swap.png";
import btc from "../../../assets/img/bg-img/btc.png";
import dow from "../../../assets/img/bg-img/dow.png";
import gold from "../../../assets/img/bg-img/gold.png";

const DIV = () => <div className="dream-blip blip1"></div>;

const SecWelcomeArea = () => {
  return (
    <section
      className="welcome_area clearfix dzsparallaxer auto-init none fullwidth"
      data-options={{ direction: "normal" }}
      id="home"
    >
      <div className="divimage dzsparallaxer--target Home1WelcomeAreaIMG"></div>

      <div className="hero-content transparent">
        {Array(4)
          .fill()
          .map((item, key) => (
            <DIV />
          ))}

        <div className="container h-100">
          <div className="row h-100 align-items-center" dir="rtl">
            <div className="col-12 col-lg-6 col-md-12">
              <SecWelcomeContent img={HomeDemo2Dollar} />
            </div>
            <div
              className="col-12  col-lg-6 col-md-12 d-none d-lg-flex h-100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ position: "relative", marginTop: "150px" }}>
                <img
                  style={{ position: "absolute", top: 0, left: 0 }}
                  src={btc}
                  alt=""
                  className="constant-move animated-image"
                />
                <img
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "20%",
                  }}
                  className="constant-move-variant "
                  src={gold}
                  alt=""
                />
                <img
                  style={{ position: "absolute", top: 0, right: 0 }}
                  src={apple}
                  className="constant-move animated-image"
                  alt=""
                />
                <img
                  style={{ position: "absolute", bottom: 0, left: 0 }}
                  src={swap}
                  className="constant-move-bottom animated-image"
                  alt=""
                />
                <img
                  style={{ position: "absolute", bottom: 0, right: 0 }}
                  src={dow}
                  className="constant-move-bottom animated-image"
                  alt=""
                />

                <img src={code} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecWelcomeArea;

const SingleCoolFact = ({ img, ico_check, text }) => {
  console.log(text, "texttexttext");
  return (
    <div className="col-12 col-sm-6 col-md-3 col-lg-3" data-aos="fade-up">
      <div className="trust-item text-center">
        <div className="ico-platform-logo">
          <img src={img} alt="" />
        </div>
        <div className="check">
          <div
            className="value"
            style={{ fontSize: "14px", whiteSpace: "nowrap" }}
          >
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCoolFact;

const SecContent = () => {
  return (
    <div className="col-12 col-md-7">
      {/* اطلاعات تماس */}
      <div className="contact_info_area d-sm-flex justify-content-between">
        <div
          className="contact_info text-center wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <h5>هدایت</h5>
          <a href>
            <p>تبلیغ‌کنندگان</p>
          </a>
          <a href>
            <p>توسعه‌دهندگان</p>
          </a>
          <a href>
            <p>منابع</p>
          </a>
          <a href>
            <p>شرکت</p>
          </a>
          <a href>
            <p>ارتباط</p>
          </a>
        </div>
        {/* اطلاعات تماس */}
        <div
          className="contact_info text-center wow fadeInUp"
          data-wow-delay="0.3s"
        >
          <h5>حریم خصوصی و شرایط استفاده</h5>
          <a href>
            <p>توافق‌نامه تبلیغ‌کنندگان</p>
          </a>
          <a href>
            <p>سیاست استفاده قابل قبول</p>
          </a>
          <a href>
            <p>سیاست حفظ حریم خصوصی</p>
          </a>
          <a href>
            <p>حریم خصوصی فناوری</p>
          </a>
          <a href>
            <p>توافق‌نامه توسعه‌دهندگان</p>
          </a>
        </div>
        {/* اطلاعات تماس */}
        <div
          className="contact_info text-center wow fadeInUp"
          data-wow-delay="0.4s"
        >
          <h5>تلفن</h5>
          <p>آدرس پستی: xx00 خیابان اتحادیه شرقی</p>
          <p>سوئیت ۱۱۰۰. دنور، CO 80237</p>
          <p>+999 90932 627</p>
          <p>support@sgb.com</p>
        </div>
      </div>
    </div>
  );
};

export default SecContent;

import CountUp from "react-countup";

const SecWelcomeMeter = ({ img }) => {
  return (
    <div className="col-12 col-lg-6" data-aos="fade-up">
      <div className="welcome-meter">
        <img
          src={img}
          className="img-responsive center-block "
          style={{ padding: "5px", borderRadius: "15px", background: "#fff" }}
          alt=""
        />
      </div>
    </div>
  );
};

export default SecWelcomeMeter;

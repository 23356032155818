function SecWelcomeContent({ img }) {
  return (
    <div className="welcome-content">
      <div className="promo-section">
        <div className="integration-link">
          <span
            className="integration-text"
            style={{ fontSize: "12px", textAlign: "center", margin: 0 }}
          >
            نسخه جدید!
          </span>
        </div>
      </div>
      <h1 style={{ fontWeight: 900, textAlign: "right" }}>
        تامین سرمایه معامله گران
      </h1>
      <p style={{ fontWeight: 300, textAlign: "right" }}>
        SGB با اختصاص ​​سرمایه لازم برای معامله‌گران در بازار فارکس با حداقل
        ریسک ممکن ایجاد شده است. این خدمات تنها مختص افراد حرفه ای است. اگر
        انگیزه های لازم برای همکاری با برترین پراپ تریدینگ ایرانی را دارید
        پیشنهاد می کنیم فرصت را از دست ندهید.
      </p>
      <div style={{ display: "flex", color: "red" }}>
        <a href="/dashboard" className="btn  dream-btn farsi-btn ml-3 ">
          شروع
        </a>
      </div>
    </div>
  );
}

export default SecWelcomeContent;

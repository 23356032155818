import React from "react";
import ReactDOM from "react-dom/client"; // Use the new 'react-dom/client'
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// If you're using a basename, uncomment and set it:
// const basename = "template_react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();

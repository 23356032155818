import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Li_A = ({ nameIco, link }) => (
  <li>
    <a href={link}>
      <FontAwesomeIcon icon={nameIco} />
    </a>
  </li>
);

const SecVerticalSocial = ({ data }) => {
  return (
    <div className="vertical-social">
      <ul style={{ marginBottom: 0 }}>
        {data &&
          data.map((item, key) => (
            <Li_A nameIco={item.nameIco} link={item.link} />
          ))}
      </ul>
    </div>
  );
};

export default SecVerticalSocial;

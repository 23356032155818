import {
  faInstagram,
  faLinkedin,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import {
  HomeDemo2IcoPlatforms1,
  HomeDemo2IcoPlatforms2,
  HomeDemo2IcoPlatforms3,
  HomeDemo2IcoPlatforms4,
  HomeDemo2IcoPlatforms5,
  HomeDemo2IcoPlatforms6,
  HomeDemo2Services1,
  HomeDemo2Services2,
  HomeDemo2Services3,
  HomeDemo2Services4,
  HomeDemo2Services5,
  HomeDemo2Services6,
  HomeDemo2TokenIcon1,
  HomeDemo2TeamImg1,
  HomeDemo2TeamImg2,
  HomeDemo2TeamImg3,
  HomeDemo2TeamImg4,
  HomeDemo2Partners1,
  HomeDemo2Partners2,
  HomeDemo2Partners3,
  HomeDemo2Partners4,
  HomeDemo2Partners5,
  HomeDemo2Partners6,
  HomeDemo2Partners7,
  HomeDemo2Partners8,
  HomeDemo2Partners9,
  HomeDemo2Partners10,
  HomeDemo2Partners11,
  HomeDemo2Partners12,
  HomeDemo2RoadmapIcons1,
  HomeDemo2RoadmapIcons2,
  HomeDemo2RoadmapIcons3,
  HomeDemo2RoadmapIcons4,
  HomeDemo2RoadmapIcons5,
  HomeDemo2RoadmapIcons6,
  HomeDemo2RoadmapIcons7,
  HomeDemo2RoadmapIcons8,
  HomeDemo2RoadmapIcons9,
  HomeDemo2BlogImg1,
  HomeDemo2BlogImg2,
  HomeDemo2BlogImg3,
  ico1,
  ico2,
  ico3,
  ico4,
} from "../../utils/allImgs";

export const VerticalSocial = [
  {
    nameIco: faInstagram,
    link: "https://www.instagram.com/sgbtradingcom?igsh=MWdjYmp1Nzk0dmJqZw==",
  },
  { nameIco: faTelegram, link: "https://t.me/SGBtrading" },
];

export const SingleCoolFact = [
  {
    img: ico2,
    ico_check: false,
    text: "تعرفه مناسب",
  },

  {
    img: ico4,
    ico_check: false,
    text: "سیستم پرداخت مطمئن",
  },
  {
    img: ico1,
    ico_check: false,
    text: "دریافت حداکثری فاند",
  },
  {
    img: ico3,
    ico_check: false,
    text: "شفافیت و اعتبار",
  },
];

export const service_single_content = [
  {
    img: HomeDemo2Services1,
    title: "سیستم هوشمند",
  },
  {
    img: HomeDemo2Services2,
    title: "مدیریت سود",
  },
  {
    img: HomeDemo2Services3,
    title: "آنالیز اخبار",
  },
  {
    img: HomeDemo2Services4,
    title: "تفکیک داده",
  },
  {
    img: HomeDemo2Services5,
    title: "معاملات هوشمند",
  },
  {
    img: HomeDemo2Services6,
    title: "پلتفرم بررسی معاملات",
  },
];

export const timelineInfo = [
  {
    right: "90%",
    ClassName: "data_cir cir_0",
    date: "Q2 2017",
    IsSpan: true,
    TextSpan: "ظهور و طراحی ایده",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons1,
  },
  {
    right: "80%",
    ClassName: "data_cir cir_1",
    date: "Q3 2017",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: true,
    TextFourLi1: "ساخت تیم",
    TextFourLi2: "تأسیس شرکت",
    TextFourLi3: "تنظیم مسائل حقوقی",
    TextFourLi4: "توسعه مفهوم",
    img: HomeDemo2RoadmapIcons2,
  },
  {
    right: "70%",
    ClassName: "data_cir cir_2",
    date: "Q4 2017",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: true,
    TextTowLi1: "شروع توسعه نسخه اولیه و نسخه کیف پول کریپتو",
    TextTowLi2: "مذاکرات در مورد مشارکت‌ها",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons3,
  },
  {
    right: "60%",
    ClassName: "data_cir cir_3",
    date: "Q1 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "راه‌اندازی وب‌سایت",
    TextThreeLi2: "انتشار وایت پیپر",
    TextThreeLi3: "شروع پیش‌فروش خصوصی",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons4,
  },
  {
    right: "50%",
    ClassName: "data_cir cir_4 red_c",
    date: "Q2 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "Crowdsale",
    TextThreeLi2: "کمپین تبلیغاتی",
    TextThreeLi3: "فهرست شدن در صرافی‌های بزرگ",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons5,
  },
  {
    right: "40%",
    ClassName: "data_cir cir_5 next",
    date: "Q3 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "انتشار نسخه کیف پول کریپتو",
    TextThreeLi2: "راه‌اندازی Testnet",
    TextThreeLi3: "توسعه نسخه اولیه پلتفرم",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons6,
  },
  {
    right: "30%",
    ClassName: "data_cir cir_6 next",
    date: "Q4 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "اپلیکیشن موبایل",
    TextThreeLi2: "Explorer",
    TextThreeLi3: "مشارکت‌های جدید و پشتیبانی از بازارهای جدید",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons7,
  },
  {
    right: "20%",
    ClassName: "data_cir cir_7 next",
    date: "Q1 2019",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "راه‌اندازی Mainnet",
    TextThreeLi2: "انتشار نسخه بتای کیف پول",
    TextThreeLi3: "پیاده‌سازی Masternodes",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons8,
  },
  {
    right: "10%",
    ClassName: "data_cir cir_8 next",
    date: "Q2 2019",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: true,
    TextTowLi1: "یکپارچه‌سازی پلتفرم با بازارهای الکترونیکی",
    TextTowLi2: "کارمزد صفر",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo2RoadmapIcons9,
  },
];

export const ServiceBlock = [
  {
    classIco: "icon ti-mobile",
    title: "Powerfull Mobile and Online App",
  },
  {
    classIco: "icon ti-widget",
    title: "Brings more Transparency and Speed",
  },
  {
    classIco: "icon ti-settings",
    title: "Special for Multiple Use Capabilities",
  },
];

export const SocialListIco = [
  { icoName: "fa fa-facebook-square" },
  { icoName: "fa fa-twitter-square" },
  { icoName: "fa fa-github-square" },
  { icoName: "fa fa-instagram" },
  { icoName: "fa fa-medium" },
];

export const FQAInfo = [
  {
    text: "What are the objectives of this token?",
    ID: "Q1",
  },
  {
    text: "What is Token Sale and pre-sale?",
    ID: "Q2",
  },
  {
    text: "What is the pre-sale start date?",
    ID: "Q3",
  },
  {
    text: "how may I take part in pre-sale?",
    ID: "Q4",
  },
];

export const DocElementTitle = [
  {
    title: "WhitePaper",
  },
  {
    title: "OnePager",
  },
  {
    title: "Rating Review",
  },
  {
    title: "Marketing Plan",
  },
];

export const TokenText = [
  {
    text: "Lorem ipsum dolor sit amet, conse ctetur elit",
    img: HomeDemo2TokenIcon1,
  },
  {
    text: "Sed quis accumsan nisi Ut ut felis",
    img: HomeDemo2TokenIcon1,
  },
  {
    text: "felis congue nisl hendrerit commodo",
    img: HomeDemo2TokenIcon1,
  },
  {
    text: "arch nemo sequi rem saepe ad quasi ullam.",
    img: HomeDemo2TokenIcon1,
  },
];

export const MissionData = [
  {
    icoName: "ti-shine",
    title: "Quality",
  },
  {
    icoName: "ti-ruler-pencil",
    title: "Creativity",
  },
  {
    icoName: "ti-heart",
    title: "Reliability",
  },
];

export const TeamMember = [
  {
    img: HomeDemo2TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo2TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo2TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo2TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
  {
    img: HomeDemo2TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo2TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo2TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo2TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
];

export const PartnersData = [
  { img: HomeDemo2Partners1 },
  { img: HomeDemo2Partners2 },
  { img: HomeDemo2Partners3 },
  { img: HomeDemo2Partners4 },
  { img: HomeDemo2Partners5 },
  { img: HomeDemo2Partners6 },
  { img: HomeDemo2Partners7 },
  { img: HomeDemo2Partners8 },
  { img: HomeDemo2Partners9 },
  { img: HomeDemo2Partners10 },
  { img: HomeDemo2Partners11 },
  { img: HomeDemo2Partners12 },
];

export const BlogPost = [
  { img: HomeDemo2BlogImg1 },
  { img: HomeDemo2BlogImg2 },
  { img: HomeDemo2BlogImg3 },
];

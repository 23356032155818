import {
  faInstagram,
  faLinkedin,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { fa1, faInstitution } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SecIco = ({ logo }) => {
  return (
    <div className="col-12 col-md-5">
      <div className="footer-copywrite-info">
        {/* Copywrite */}
        <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
          <div className="footer-logo" style={{ textAlign: "right" }}>
            <a href="#">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <p style={{ textAlign: "right" }}>
            در راستای تامین سرمایه معامله گران برای ترید در بازارهای مالی با
            حداقل ریسک ممکن تشکیل شده و در این راه خدماتی را به تریدرها ارائه
            میکند.
          </p>
        </div>
        {/* Social Icon */}
        <div className="footer-social-info wow fadeInUp" data-wow-delay="0.4s">
          <a
            href="https://www.instagram.com/sgbtradingcom?igsh=MWdjYmp1Nzk0dmJqZw=="
            style={{ marginLeft: "15px" }}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://t.me/SGBtrading">
            <FontAwesomeIcon icon={faTelegram} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecIco;

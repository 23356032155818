import NavbarLogo from "../../assets/img/core-img/logo.png";

import FooterLogo from "../../assets/img/core-img/logo-small-mono.svg";
import FooterPattern from "../../assets/img/core-img/pattern.png";
import FooterBg1 from "../../assets/img/core-img/footer-bg1.png";

import HomeDemo1 from "../../assets/img/demos/demo-1.png";
import HomeDemo2 from "../../assets/img/demos/demo-2.png";
import HomeDemo3 from "../../assets/img/demos/demo-3.png";
import one from "../";

/////////////////  Imgs for HomeDemo1  /////////////////

import HomeDemo1Dollar from "../../assets/img/svg/img-dollar.svg";

import HomeDemo1IcoPlatforms1 from "../../assets/img/ico-platforms/1.png";
import HomeDemo1IcoPlatforms2 from "../../assets/img/ico-platforms/2.png";
import HomeDemo1IcoPlatforms3 from "../../assets/img/ico-platforms/3.png";
import HomeDemo1IcoPlatforms4 from "../../assets/img/ico-platforms/4.png";
import HomeDemo1IcoPlatforms5 from "../../assets/img/ico-platforms/5.png";
import HomeDemo1IcoPlatforms6 from "../../assets/img/ico-platforms/6.png";
import ico1 from "../../assets/img/svg/01.svg";
import ico2 from "../../assets/img/svg/02.svg";
import ico3 from "../../assets/img/svg/03.svg";
import ico4 from "../../assets/img/svg/04.svg";

import HomeDemo1About1 from "../../assets/img/svg/about1.svg";

import HomeDemo1Solution from "../../assets/img/solution.png";

import HomeDemo1VideoBg4 from "../../assets/img/bg-img/bg-4.jpg";

import HomeDemo1Services1 from "../../assets/img/services/1.svg";
import HomeDemo1Services2 from "../../assets/img/services/2.svg";
import HomeDemo1Services3 from "../../assets/img/services/3.svg";
import HomeDemo1Services4 from "../../assets/img/services/4.svg";
import HomeDemo1Services5 from "../../assets/img/services/5.svg";
import HomeDemo1Services6 from "../../assets/img/services/6.svg";

import HomeDemo1ImgPhone from "../../assets/img/phone.png";
import HomeDemo1RingsBg from "../../assets/img/core-img/rings-bg.png";

import HomeDemo1Allocation from "../../assets/img/core-img/allocation.png";

import HomeDemo1TokenIcon1 from "../../assets/img/svg/token-icon-1.svg";
import HomeDemo1TokenIcon2 from "../../assets/img/svg/token-icon-2.svg";
import HomeDemo1TokenIcon3 from "../../assets/img/svg/token-icon-3.svg";
import HomeDemo1TokenIcon4 from "../../assets/img/svg/token-icon-4.svg";

import HomeDemo1TeamImg1 from "../../assets/img/team-img/1.png";
import HomeDemo1TeamImg2 from "../../assets/img/team-img/2.png";
import HomeDemo1TeamImg3 from "../../assets/img/team-img/3.png";
import HomeDemo1TeamImg4 from "../../assets/img/team-img/4.png";

import HomeDemo1Partners1 from "../../assets/img/partners/1.png";
import HomeDemo1Partners2 from "../../assets/img/partners/2.png";
import HomeDemo1Partners3 from "../../assets/img/partners/3.png";
import HomeDemo1Partners4 from "../../assets/img/partners/4.png";
import HomeDemo1Partners5 from "../../assets/img/partners/5.png";
import HomeDemo1Partners6 from "../../assets/img/partners/6.png";
import HomeDemo1Partners7 from "../../assets/img/partners/7.png";
import HomeDemo1Partners8 from "../../assets/img/partners/8.png";
import HomeDemo1Partners9 from "../../assets/img/partners/9.png";
import HomeDemo1Partners10 from "../../assets/img/partners/10.png";
import HomeDemo1Partners11 from "../../assets/img/partners/11.png";
import HomeDemo1Partners12 from "../../assets/img/partners/12.png";

import HomeDemo1BlogImg1 from "../../assets/img/blog-img/1.jpg";
import HomeDemo1BlogImg2 from "../../assets/img/blog-img/2.jpg";
import HomeDemo1BlogImg3 from "../../assets/img/blog-img/3.jpg";

/////////////////  Imgs for HomeDemo2  /////////////////

import HomeDemo2Dollar from "../../assets/img/svg/img-dollar.svg";

import HomeDemo2IcoPlatforms1 from "../../assets/img/ico-platforms/1.png";
import HomeDemo2IcoPlatforms2 from "../../assets/img/ico-platforms/2.png";
import HomeDemo2IcoPlatforms3 from "../../assets/img/ico-platforms/3.png";
import HomeDemo2IcoPlatforms4 from "../../assets/img/ico-platforms/4.png";
import HomeDemo2IcoPlatforms5 from "../../assets/img/ico-platforms/5.png";
import HomeDemo2IcoPlatforms6 from "../../assets/img/ico-platforms/6.png";

import HomeDemo2About1 from "../../assets/img/svg/about1.svg";

import HomeDemo2Solution from "../../assets/img/solution.png";

import HomeDemo2VideoBg4 from "../../assets/img/bg-img/bg-4.jpg";

import HomeDemo2Services1 from "../../assets/img/services/1.svg";
import HomeDemo2Services2 from "../../assets/img/services/2.svg";
import HomeDemo2Services3 from "../../assets/img/services/3.svg";
import HomeDemo2Services4 from "../../assets/img/services/4.svg";
import HomeDemo2Services5 from "../../assets/img/services/5.svg";
import HomeDemo2Services6 from "../../assets/img/services/6.svg";

import HomeDemo2ImgPhone from "../../assets/img/phone.png";
import HomeDemo2RingsBg from "../../assets/img/core-img/rings-bg.png";

import HomeDemo2Allocation from "../../assets/img/core-img/allocation.png";

import HomeDemo2BgRoadmap from "../../assets/img/bg-img/bg-roadmap.png";

import HomeDemo2RoadmapIcons1 from "../../assets/img/roadmap-icons/1.svg";
import HomeDemo2RoadmapIcons2 from "../../assets/img/roadmap-icons/2.svg";
import HomeDemo2RoadmapIcons3 from "../../assets/img/roadmap-icons/3.svg";
import HomeDemo2RoadmapIcons4 from "../../assets/img/roadmap-icons/4.svg";
import HomeDemo2RoadmapIcons5 from "../../assets/img/roadmap-icons/5.svg";
import HomeDemo2RoadmapIcons6 from "../../assets/img/roadmap-icons/6.svg";
import HomeDemo2RoadmapIcons7 from "../../assets/img/roadmap-icons/7.svg";
import HomeDemo2RoadmapIcons8 from "../../assets/img/roadmap-icons/8.svg";
import HomeDemo2RoadmapIcons9 from "../../assets/img/roadmap-icons/9.svg";

import HomeDemo2TokenIcon1 from "../../assets/img/svg/token-icon-1.svg";
import HomeDemo2TokenIcon2 from "../../assets/img/svg/token-icon-2.svg";
import HomeDemo2TokenIcon3 from "../../assets/img/svg/token-icon-3.svg";
import HomeDemo2TokenIcon4 from "../../assets/img/svg/token-icon-4.svg";

import HomeDemo2TeamImg1 from "../../assets/img/team-img/1.png";
import HomeDemo2TeamImg2 from "../../assets/img/team-img/2.png";
import HomeDemo2TeamImg3 from "../../assets/img/team-img/3.png";
import HomeDemo2TeamImg4 from "../../assets/img/team-img/4.png";

import HomeDemo2Partners1 from "../../assets/img/partners/1.png";
import HomeDemo2Partners2 from "../../assets/img/partners/2.png";
import HomeDemo2Partners3 from "../../assets/img/partners/3.png";
import HomeDemo2Partners4 from "../../assets/img/partners/4.png";
import HomeDemo2Partners5 from "../../assets/img/partners/5.png";
import HomeDemo2Partners6 from "../../assets/img/partners/6.png";
import HomeDemo2Partners7 from "../../assets/img/partners/7.png";
import HomeDemo2Partners8 from "../../assets/img/partners/8.png";
import HomeDemo2Partners9 from "../../assets/img/partners/9.png";
import HomeDemo2Partners10 from "../../assets/img/partners/10.png";
import HomeDemo2Partners11 from "../../assets/img/partners/11.png";
import HomeDemo2Partners12 from "../../assets/img/partners/12.png";

import HomeDemo2BlogImg1 from "../../assets/img/blog-img/1.jpg";
import HomeDemo2BlogImg2 from "../../assets/img/blog-img/2.jpg";
import HomeDemo2BlogImg3 from "../../assets/img/blog-img/3.jpg";

/////////////////  Imgs for HomeDemo3  /////////////////

import HomeDemo3Dollar from "../../assets/img/svg/img-dollar.svg";

import HomeDemo3IcoPlatforms1 from "../../assets/img/ico-platforms/1.png";
import HomeDemo3IcoPlatforms2 from "../../assets/img/ico-platforms/2.png";
import HomeDemo3IcoPlatforms3 from "../../assets/img/ico-platforms/3.png";
import HomeDemo3IcoPlatforms4 from "../../assets/img/ico-platforms/4.png";
import HomeDemo3IcoPlatforms5 from "../../assets/img/ico-platforms/5.png";
import HomeDemo3IcoPlatforms6 from "../../assets/img/ico-platforms/6.png";

import HomeDemo3About1 from "../../assets/img/svg/about1.svg";

import HomeDemo3Solution from "../../assets/img/solution.png";

import HomeDemo3VideoBg4 from "../../assets/img/bg-img/bg-4.jpg";

import HomeDemo3Services1 from "../../assets/img/services/1.svg";
import HomeDemo3Services2 from "../../assets/img/services/2.svg";
import HomeDemo3Services3 from "../../assets/img/services/3.svg";
import HomeDemo3Services4 from "../../assets/img/services/4.svg";
import HomeDemo3Services5 from "../../assets/img/services/5.svg";
import HomeDemo3Services6 from "../../assets/img/services/6.svg";

import HomeDemo3ImgPhone from "../../assets/img/phone.png";
import HomeDemo3RingsBg from "../../assets/img/core-img/rings-bg.png";

import HomeDemo3Allocation from "../../assets/img/core-img/allocation.png";

import HomeDemo3BgRoadmap from "../../assets/img/bg-img/bg-roadmap.png";

import HomeDemo3RoadmapIcons1 from "../../assets/img/roadmap-icons/1.svg";
import HomeDemo3RoadmapIcons2 from "../../assets/img/roadmap-icons/2.svg";
import HomeDemo3RoadmapIcons3 from "../../assets/img/roadmap-icons/3.svg";
import HomeDemo3RoadmapIcons4 from "../../assets/img/roadmap-icons/4.svg";
import HomeDemo3RoadmapIcons5 from "../../assets/img/roadmap-icons/5.svg";
import HomeDemo3RoadmapIcons6 from "../../assets/img/roadmap-icons/6.svg";
import HomeDemo3RoadmapIcons7 from "../../assets/img/roadmap-icons/7.svg";
import HomeDemo3RoadmapIcons8 from "../../assets/img/roadmap-icons/8.svg";
import HomeDemo3RoadmapIcons9 from "../../assets/img/roadmap-icons/9.svg";

import HomeDemo3TokenIcon1 from "../../assets/img/svg/token-icon-1.svg";
import HomeDemo3TokenIcon2 from "../../assets/img/svg/token-icon-2.svg";
import HomeDemo3TokenIcon3 from "../../assets/img/svg/token-icon-3.svg";
import HomeDemo3TokenIcon4 from "../../assets/img/svg/token-icon-4.svg";

import HomeDemo3TeamImg1 from "../../assets/img/team-img/1.png";
import HomeDemo3TeamImg2 from "../../assets/img/team-img/2.png";
import HomeDemo3TeamImg3 from "../../assets/img/team-img/3.png";
import HomeDemo3TeamImg4 from "../../assets/img/team-img/4.png";

import HomeDemo3Partners1 from "../../assets/img/partners/1.png";
import HomeDemo3Partners2 from "../../assets/img/partners/2.png";
import HomeDemo3Partners3 from "../../assets/img/partners/3.png";
import HomeDemo3Partners4 from "../../assets/img/partners/4.png";
import HomeDemo3Partners5 from "../../assets/img/partners/5.png";
import HomeDemo3Partners6 from "../../assets/img/partners/6.png";
import HomeDemo3Partners7 from "../../assets/img/partners/7.png";
import HomeDemo3Partners8 from "../../assets/img/partners/8.png";
import HomeDemo3Partners9 from "../../assets/img/partners/9.png";
import HomeDemo3Partners10 from "../../assets/img/partners/10.png";
import HomeDemo3Partners11 from "../../assets/img/partners/11.png";
import HomeDemo3Partners12 from "../../assets/img/partners/12.png";

import HomeDemo3BlogImg1 from "../../assets/img/blog-img/1.jpg";
import HomeDemo3BlogImg2 from "../../assets/img/blog-img/2.jpg";
import HomeDemo3BlogImg3 from "../../assets/img/blog-img/3.jpg";

export {
  HomeDemo1,
  HomeDemo2,
  HomeDemo3,
  FooterLogo,
  FooterPattern,
  FooterBg1,
  NavbarLogo,
  HomeDemo1Dollar,
  HomeDemo1IcoPlatforms1,
  HomeDemo1IcoPlatforms2,
  HomeDemo1IcoPlatforms3,
  HomeDemo1IcoPlatforms4,
  HomeDemo1IcoPlatforms5,
  HomeDemo1IcoPlatforms6,
  HomeDemo1About1,
  HomeDemo1Solution,
  HomeDemo1VideoBg4,
  HomeDemo1Services1,
  HomeDemo1Services2,
  HomeDemo1Services3,
  HomeDemo1Services4,
  HomeDemo1Services5,
  HomeDemo1Services6,
  HomeDemo1ImgPhone,
  HomeDemo1RingsBg,
  HomeDemo1Allocation,
  HomeDemo1TokenIcon1,
  HomeDemo1TokenIcon2,
  HomeDemo1TokenIcon3,
  HomeDemo1TokenIcon4,
  HomeDemo1TeamImg1,
  HomeDemo1TeamImg2,
  HomeDemo1TeamImg3,
  HomeDemo1TeamImg4,
  HomeDemo1Partners1,
  HomeDemo1Partners2,
  HomeDemo1Partners3,
  HomeDemo1Partners4,
  HomeDemo1Partners5,
  HomeDemo1Partners6,
  HomeDemo1Partners7,
  HomeDemo1Partners8,
  HomeDemo1Partners9,
  HomeDemo1Partners10,
  HomeDemo1Partners11,
  HomeDemo1Partners12,
  HomeDemo1BlogImg1,
  HomeDemo1BlogImg2,
  HomeDemo1BlogImg3,
  HomeDemo2Dollar,
  HomeDemo2IcoPlatforms1,
  HomeDemo2IcoPlatforms2,
  HomeDemo2IcoPlatforms3,
  HomeDemo2IcoPlatforms4,
  HomeDemo2IcoPlatforms5,
  HomeDemo2IcoPlatforms6,
  HomeDemo2About1,
  HomeDemo2Solution,
  HomeDemo2VideoBg4,
  HomeDemo2Services1,
  HomeDemo2Services2,
  HomeDemo2Services3,
  HomeDemo2Services4,
  HomeDemo2Services5,
  HomeDemo2Services6,
  HomeDemo2ImgPhone,
  HomeDemo2RingsBg,
  HomeDemo2Allocation,
  HomeDemo2BgRoadmap,
  HomeDemo2RoadmapIcons1,
  HomeDemo2RoadmapIcons2,
  HomeDemo2RoadmapIcons3,
  HomeDemo2RoadmapIcons4,
  HomeDemo2RoadmapIcons5,
  HomeDemo2RoadmapIcons6,
  HomeDemo2RoadmapIcons7,
  HomeDemo2RoadmapIcons8,
  HomeDemo2RoadmapIcons9,
  HomeDemo2TokenIcon1,
  HomeDemo2TokenIcon2,
  HomeDemo2TokenIcon3,
  HomeDemo2TokenIcon4,
  HomeDemo2TeamImg1,
  HomeDemo2TeamImg2,
  HomeDemo2TeamImg3,
  HomeDemo2TeamImg4,
  HomeDemo2Partners1,
  HomeDemo2Partners2,
  HomeDemo2Partners3,
  HomeDemo2Partners4,
  HomeDemo2Partners5,
  HomeDemo2Partners6,
  HomeDemo2Partners7,
  HomeDemo2Partners8,
  HomeDemo2Partners9,
  HomeDemo2Partners10,
  HomeDemo2Partners11,
  HomeDemo2Partners12,
  HomeDemo2BlogImg1,
  HomeDemo2BlogImg2,
  HomeDemo2BlogImg3,
  HomeDemo3Dollar,
  HomeDemo3IcoPlatforms1,
  HomeDemo3IcoPlatforms2,
  HomeDemo3IcoPlatforms3,
  HomeDemo3IcoPlatforms4,
  HomeDemo3IcoPlatforms5,
  HomeDemo3IcoPlatforms6,
  HomeDemo3About1,
  HomeDemo3Solution,
  HomeDemo3VideoBg4,
  HomeDemo3Services1,
  HomeDemo3Services2,
  HomeDemo3Services3,
  HomeDemo3Services4,
  HomeDemo3Services5,
  HomeDemo3Services6,
  HomeDemo3ImgPhone,
  HomeDemo3RingsBg,
  HomeDemo3Allocation,
  HomeDemo3BgRoadmap,
  HomeDemo3RoadmapIcons1,
  HomeDemo3RoadmapIcons2,
  HomeDemo3RoadmapIcons3,
  HomeDemo3RoadmapIcons4,
  HomeDemo3RoadmapIcons5,
  HomeDemo3RoadmapIcons6,
  HomeDemo3RoadmapIcons7,
  HomeDemo3RoadmapIcons8,
  HomeDemo3RoadmapIcons9,
  HomeDemo3TokenIcon1,
  HomeDemo3TokenIcon2,
  HomeDemo3TokenIcon3,
  HomeDemo3TokenIcon4,
  HomeDemo3TeamImg1,
  HomeDemo3TeamImg2,
  HomeDemo3TeamImg3,
  HomeDemo3TeamImg4,
  HomeDemo3Partners1,
  HomeDemo3Partners2,
  HomeDemo3Partners3,
  HomeDemo3Partners4,
  HomeDemo3Partners5,
  HomeDemo3Partners6,
  HomeDemo3Partners7,
  HomeDemo3Partners8,
  HomeDemo3Partners9,
  HomeDemo3Partners10,
  HomeDemo3Partners11,
  HomeDemo3Partners12,
  HomeDemo3BlogImg1,
  HomeDemo3BlogImg2,
  HomeDemo3BlogImg3,
  ico1,
  ico2,
  ico3,
  ico4,
};
